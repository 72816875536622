<style lang="scss">
    @import "~@/assets/css/var";
    .login-panel {
        display: flex;
        width: 100%;
        height: 100%;

        &-left {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            background: url('~@/assets/img/login/back.png') no-repeat center / 100% 100% !important;

            img {
                width: 70%;
                max-width: 400px;
            }
        }
        &-right {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;


            &__logo {
                text-align: center;
                font-size: 0;
                img {
                    width: 42px;
                }
            }
            &__title {
                margin-top: 20px;
                font-size: 32px;
                line-height: 1;
                text-align: center;
                font-weight: 500;
                color: #333;
            }

            .el-form {
                margin-top: 55px;
                width: 324px;
                &-item {
                    &__label {
                        font-size: 15px;
                        line-height: 1;
                        padding: 0;
                    }
                    &__content {
                        .el-input {
                            padding: 8px 0;
                            border-bottom: 1px solid #C0C0C0;
                        }
                        .sms-input{
                            display: flex ;
                            align-items: center ;
                            position: relative;
                        }
                       .el-input__inner {
                           border: 0;
                           font-size: 14px;
                           line-height: 1;
                           padding: 0;
                           &::placeholder {
                               color: #999;
                           }
                       }
                    }

                    &__error {
                        position: absolute;
                        z-index: 100;
                        bottom:  -15px;
                        left: 0;
                        right: 0;
                        background: $red;
                        color: #fff;
                        font-size: 12px;
                        line-height: 30px;
                        height: 30px;
                        padding: 0 10px;
                    }
                }
            }

            .login-btn {
                padding-top: 40px;
                .el-button {
                    margin: 0;
                    display: block;
                    width: 100%;
                    line-height: 50px;
                    padding: 0;
                    border-radius: 26px;
                    font-size: 14px;
                    & + .el-button {
                        margin-top: 22px;
                    }

                    i {
                        font-size: 10px;
                        margin-left: 10px;
                    }

                    border-color: $main;
                    color: $main;

                    &:hover {
                        background: #fff;
                    }

                    &--primary {
                        color: #fff;
                        background: linear-gradient(0deg, $main, #435FC0);

                        &:hover {
                            background: linear-gradient(0deg, $main, #435FC0);
                        }
                    }

                    &:hover {
                        opacity: .7;
                    }
                }
                .cb-login{
                    color: $main;
                    text-align: center;
                    line-height: 40px;
                }
            }

            .tips {
                color:#999;
            }
            .sms-btn {
                border: 1px solid $main !important;
                color: $main !important;
                background: #eef1fc !important;
                position: absolute;
                right: 20px;
                bottom: -2px;
                &:hover {
                    color: #fff !important;
                    background-color: $main !important;
                }
            }
            .is-disabled{
                &:hover {
                    color: $main !important;
                    background: #eef1fc !important;
                }
            }
        }

        .el-input-group__append, .el-input-group__prepend {
            background: transparent;
            border: 0;
            padding: 0;
        }
    }


    .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
        content: '*';
        color: $red;
        margin-left: 4px;
        float: right;
    }
</style>

<template>
    <div class="login-panel">
        <div class="login-panel-left">
            <img src="@/assets/img/login/part.png" alt="part">
        </div>
        <div class="login-panel-right">
            <div>
                <div class="login-panel-right__logo">
                    <img src="@/assets/img/logo-clear.png" alt="">
                </div>
                <p class="login-panel-right__title">{{title}}</p>

                <div class="login-panel-right__info">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['title'],
    }
</script>